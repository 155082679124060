<template>
    <div>
        <button type="button" class="dropdown-toggle" @click="toggle_onclick">
            <span :class="`fa-solid ${icon} tw-text-18`"></span>
        </button>
        <div class="dropdown-menu" :class="{'show': if_show_menu}" v-if="screen_width >= 1024">
            <slot name="menu_inner"></slot>
        </div>
    </div>
</template>

<script>
import {ref, onMounted} from "vue"

export default {
    props: {
        name: {
            type: String,
            required: true
        },
        icon: {
            type: String,
            required: true
        }
    },
    setup(props) {
        const icon = props.icon
        const screen_width = ref(window.innerWidth)
        const if_show_menu = ref(false)
        const toggle_onclick = () => {
            if (screen_width.value >= 1024) {
                if_show_menu.value = !if_show_menu.value
            } else {
                const modal = new bootstrap.Modal(document.getElementById(`${props.name}_modal`), {})
                modal.show()
            }
        }
        onMounted(() => {
            $(document).on('click', function (e) {
                if ( !$(`#${props.name}`).is(e.target) && !$(`#${props.name} *`).is(e.target) ) {
                    if_show_menu.value = false
                }
            })
        })
        return {
            screen_width,
            if_show_menu,
            toggle_onclick,
            icon
        }
    }
}
</script>
