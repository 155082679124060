<template>
    <section class="home-section section-features" id="features">
        <div class="section-inner tw-flex tw-flex-col tw-items-center tw-justify-center tw-h-full">
            <h2 class="section-title tw-text-center">{{ t('features.title') }}</h2>
            <swiper
                class="features-swiper"
                :navigation="JSON.parse(JSON.stringify(swiper_options.navigation))"
                :loop="swiper_options.loop"
                :autoplay="JSON.parse(JSON.stringify(swiper_options.autoplay))"
                :autoHeight="swiper_options.autoHeight"
                :breakpoints="JSON.parse(JSON.stringify(swiper_options.breakpoints))">
                <swiper-slide v-for="(slide, index) in swiper_slides" :key="index">
                    <div class="swiper-slide-inner tw-flex tw-items-center tw-flex-col sm:landscape:tw-flex-row sm:landscape:tw-gap-10 md:landscape:tw-gap-16">
                        <div class="pic">
                            <img :src="slide.image" draggable="false" :alt="t(slide.title)" class="tw-block" />
                        </div>
                        <div class="content tw-mt-12 sm:landscape:tw-mt-0">
                            <h3 class="title tw-whitespace-pre-line">{{ t(slide.title) }}</h3>
                            <p class="text tw-mt-3 md:tw-mt-5 lg:tw-mt-8 tw-text-justify">{{ t(slide.text) }}</p>
                        </div>
                    </div>
                </swiper-slide>
                <div class="swiper-buttons tw-mt-8 sm:tw-mt-0">
                    <button type="button" class="swiper-button prev btn-style accent-cyan tw-rounded-full"><span class="fa-solid fa-arrow-left"></span></button>
                    <button type="button" class=" swiper-button next btn-style accent-cyan tw-rounded-full"><span class="fa-solid fa-arrow-right"></span></button>
                </div>
            </swiper>
        </div>
    </section>
</template>
<script>
import { reactive } from "vue"
import { useI18n } from "vue-i18n"
import config from "../../config"

import SwiperCore, { Navigation, Autoplay } from "swiper"
import { Swiper, SwiperSlide } from "swiper/vue";
SwiperCore.use([Navigation, Autoplay])

export default {
    components: { Swiper, SwiperSlide },
    setup() {
        const { t } = useI18n()
        const swiper_options = reactive({
            navigation: {
                nextEl: '.swiper-button.next',
                prevEl: '.swiper-button.prev'
            },
            loop: true,
            autoplay: {
                delay: 8000,
                disableOnInteraction: false
            },
            autoHeight: true,
            breakpoints: {
                576: {
                    autoHeight: false
                }
            }
        })
        const swiper_slides = reactive([
            {
                image: config.imageUrl('home/features/slide-01.svg'),
                title: 'features.slides[0].title',
                text: 'features.slides[0].text'
            },
            {
                image: config.imageUrl('home/features/slide-02.svg'),
                title: 'features.slides[1].title',
                text: 'features.slides[1].text'
            },
            {
                image: config.imageUrl('home/features/slide-03.svg'),
                title: 'features.slides[2].title',
                text: 'features.slides[2].text'
            },
            {
                image: config.imageUrl('home/features/slide-04.svg'),
                title: 'features.slides[3].title',
                text: 'features.slides[3].text'
            }
        ])
        return {
            t,
            swiper_options,
            swiper_slides
        }
    }
}
</script>
