<template>
    <modal :id="`${name}_modal`" :dialog-class="`${name}-modal modal-sm`" :hide-header="true" :hide-footer="true">
        <slot name="modal_inner"></slot>
    </modal>
</template>

<script>
import Modal from "../utilities/modal.vue";
export default {
    components: {Modal},
    props: {
        name: {
            type: String,
            required: true
        }
    },
    setup(props) {
        const name = props.name
        return {
            name
        }
    }
}
</script>
