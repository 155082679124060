<template>
    <intro></intro>
    <features></features>
    <plans></plans>
</template>
<script>
import Intro from "./intro"
import Features from "./features"
import Plans from "./plans"

export default {
    components: { Intro, Features, Plans }
}
</script>
