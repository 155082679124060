<template>
    <metainfo>
        <template v-slot:title="{ content }">{{ content ? `${content} | ` + $t('site_name') : $t('site_name') }}</template>
    </metainfo>
    <router-view></router-view>
</template>
<script>
import { useMeta } from "vue-meta"
export default {
    setup() {
        useMeta({
            title: ''
        })
    }
}
</script>
