<template>
    <div class="wrapper" :class="locale">
        <site-header></site-header>
        <main class="main">
            <router-view></router-view>
        </main>
        <site-footer></site-footer>
    </div>
</template>
<script>
import SiteHeader from "./site-header"
import SiteFooter from "./site-footer"
import { useI18n } from "vue-i18n"

export default {
    components: { SiteHeader, SiteFooter },
    setup() {
        const { locale } = useI18n()
        return {
            locale
        }
    }
}
</script>
