<template>
    <footer class="site-footer tw-py-6">
        <div class="inner ctn tw-flex tw-items-center tw-flex-col md:tw-flex-row md:tw-justify-center tw-text-12">
            <p class="tw-text-center md:tw-text-left">
                <i18n-t keypath="footer.copyright">
                    <template #year>{{ year }}</template>
                </i18n-t>
            </p>
      </div>
    </footer>
</template>
<script>

export default {
    setup() {
        const date = new Date()
        const year = date.getFullYear()
        return {
            year
        }
    }
}
</script>
