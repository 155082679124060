<template>
    <div class="row">
        <div class="input-group" :class="input_group_classes">
            <div class="form-label">{{ label }} <span class="tw-text-red-400" v-if="isRequired">*</span></div>
            <input :type="inputType" class="form-control" :value="modelValue" @input="updateValue" @focus="onFocus" @blur="onBlur" :placeholder="placeholder" v-if="!isTextarea && !$slots['custom-content']" />
            <textarea class="form-control" rows="5" @input="updateValue" @focus="onFocus" @blur="onBlur" v-if="isTextarea && !$slots['custom-content']"></textarea>
            <slot name="custom-content" v-if="$slots['custom-content']"></slot>
        </div>
        <div class="error-messages" v-if="$slots['error-messages']">
            <slot name="error-messages"></slot>
        </div>
    </div>
</template>
<script>
import { ref, computed } from "vue"

export default {
    props: {
        inputType: {
            type: String,
            default: 'text'
        },
        groupClass: {
            type: String,
            default: null
        },
        modelValue: {
            type: String,
            default: null
        },
        label: {
            type: String,
            default: null
        },
        placeholder: {
            type: String,
            default: null
        },
        isRequired: {
            type: Boolean,
            default: false
        },
        isTextarea: {
            type: Boolean,
            default: false
        },
        invalid: {
            type: Boolean,
            default: false
        }
    },
    setup(props, context) {
        const focused = ref(false)
        const onFocus = () => {
            focused.value = true
        }
        const onBlur = () => {
            focused.value = false
        }
        const updateValue = (event) => {
            context.emit('update:modelValue', event.target.value)
        }
        const input_group_classes = computed(() => {
            let classes = props.groupClass ? props.groupClass : ''
            if ( focused.value && props.invalid ) {
                classes = classes + ' focused error'
            } else if ( focused.value && !props.invalid ) {
                classes = classes + ' focused'
            } else if ( !focused.value && props.invalid ) {
                classes = classes + ' error'
            }
            return classes
        })
        return {
            focused, onFocus, onBlur,
            updateValue, input_group_classes
        }
    }
}
</script>
