<template>
    <div class="modal fade" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" :class="dialogClass">
            <div class="modal-content">
                <button type="button" class="btn-close btn-style accent-cyan-lighter" data-bs-dismiss="modal">
                    <span class="fa-solid fa-xmark"></span>
                </button>
                <div class="modal-header" :class="headerClass" v-if="!hideHeader">
                    <slot name="modal-header" v-if="$slots['modal-header']"></slot>
                    <h5 class="modal-title" v-else>{{ title }}</h5>
                </div>
                <div class="modal-body" :class="bodyClass">
                    <slot></slot>
                </div>
                <div class="modal-footer" :class="footerClass" v-if="!hideFooter">
                    <slot name="modal-footer" v-if="$slots['modal-footer']"></slot>
                    <div class="tw-flex tw-items-center tw-justify-center tw-gap-1.5" v-else>
                        <button type="button" class="btn-style" @click="onOk">{{ okTitle }}</button>
                        <button type="button" class="btn-style dismiss" data-bs-dismiss="modal">{{ cancelTitle }}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        hideHeader: {
            type: Boolean,
            default: false
        },
        hideFooter: {
            type: Boolean,
            default: false
        },
        headerClass: {
            type: String
        },
        dialogClass: {
            type: String
        },
        footerClass: {
            type: String
        },
        bodyClass: {
            type: String
        },
        title: {
            type: String
        },
        onOk: {
            type: Function,
            default: function () {}
        },
        okTitle: {
            type: String,
            default: 'OK'
        },
        cancelTitle: {
            type: String,
            default: 'Cancel'
        }
    }
}
</script>
