<template>
    <section class="home-section section-intro" id="intro">
        <swiper class="intro-swiper" :allow-touch-move="false">
            <swiper-slide v-for="(slide, index) in swiper_slides" :key="index" :class="{'cover': index === 0}">
                <div class="swiper-slide-inner tw-gap-x-20" :class="{'sm:landscape:tw-flex-row-reverse': index % 2 !== 0}">
                    <div class="pic">
                        <img :src="slide.image" draggable="false" :alt="t(slide.title)" class="tw-block" />
                    </div>
                    <div class="content">
                        <div class="content-txt">
                            <h1 class="title tw-whitespace-pre-line" v-if="index === 0">{{ t(slide.title) }}</h1>
                            <h2 class="title tw-whitespace-pre-line" v-else>{{ t(slide.title) }}</h2>
                            <p class="text lg:tw-whitespace-pre-line">{{ t(slide.text) }}</p>
                        </div>
                        <div class="content-buttons tw-mt-6 xs:tw-mt-8 lg:tw-mt-12">
                            <button type="button" class="btn-view btn-style accent-cyan tw-rounded tw-flex tw-items-center" @click="scroll_to_plans" v-if="index === 0">
                                <span class="tw-block">{{ t('intro.slides[0].view_more') }}</span><span class="fa-solid fa-arrow-down tw-ml-3 tw-mb-0.5"></span>
                            </button>
                            <ul class="tw-list-none tw-flex tw-gap-1 xs:tw-gap-1.5 tw-items-center navigation" v-else>
                                <li><button type="button" class="btn-style accent-cyan tw-rounded" @click="slide_prev"><span class="fa-solid fa-arrow-left"></span></button></li>
                                <li><button type="button" class="btn-style accent-cyan tw-rounded" @click="slide_next"><span class="fa-solid fa-arrow-right"></span></button></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </swiper-slide>
        </swiper>
    </section>
</template>
<script>
import { ref, reactive, onMounted } from "vue"
import { useI18n } from "vue-i18n"
import config from "../../config"

import SwiperCore, { Navigation } from "swiper"
import { Swiper, SwiperSlide } from "swiper/vue"
SwiperCore.use([ Navigation ])

export default {
    components: { Swiper, SwiperSlide },
    setup() {
        const { t } = useI18n()
        const swiper_slides = reactive([
            {
                image: config.imageUrl('home/intro/visual.svg'),
                title: 'intro.slides[0].title',
                text: 'intro.slides[0].text'
            }
        ])
        let intro_swiper = ref(null)
        const slide_next = () => {
            intro_swiper.slideNext()
        }
        const slide_prev = () => {
            intro_swiper.slidePrev()
        }
        const scroll_to_plans = () => {
            const is_portrait = window.innerWidth/window.innerHeight < 1
            const offsetTop = !is_portrait ? $('#plans').offset().top : $('#plans').offset().top - $('#site-header').height()
            $('html, body').animate({ scrollTop: offsetTop }, 500)
        }
        onMounted(() => {
            intro_swiper = document.querySelector('.intro-swiper').swiper
        })
        return {
            t, swiper_slides, slide_next, slide_prev, scroll_to_plans
        }
    }
}
</script>
