import { createRouter, createWebHistory } from "vue-router"
import i18n from "../i18n"
import Wrapper from "../components/general/wrapper"
import Home from "../components/home/home"

const routes = [
    {
        path: '/',
        redirect: { path: '/zh-TW' }
    },
    {
        path: '/:lang',
        component: Wrapper,
        children: [
            {
                path: '',
                name: 'Home',
                component: Home
            }
        ]
    }
]

const router = createRouter({
    base: process.env.MIX_BASE_URL,
    history: createWebHistory(process.env.MIX_BASE_URL),
    routes,
    scrollBehavior() {
        return { top: 0 }
    }
})

router.beforeEach((to, from , next) => {
    const locale = to.params.lang ? to.params.lang : 'zh-TW'
    axios.get('/locale/' + locale).then(() => {
        i18n.global.locale.value = locale
        next()
    }).catch(() => {
        i18n.global.locale.value = 'zh-TW'
        router.push('/zh-TW')
    })
})

export default router
