// vue
import { createApp } from "vue"
const app = createApp({})

// axios
import config from "./config"
window.axios = require('axios')
window.axios = axios.create({
    baseURL: config.baseUrl
})

// i18n
import i18n from "./i18n"
app.use(i18n)

// router
import router from "./router"
app.use(router)

// meta
import VueMetaManager from "./meta"
app.use(VueMetaManager)

// mount app
import App from "./components/general/app"
app.component('app', App)
app.mount('#app')

// vendors
require('./bootstrap')
