<template>
    <section class="home-section section-plans" id="plans">
        <h2 class="tw-hidden">{{ t('plans.title') }}</h2>
        <swiper class="section-plans-block info tw-relative plans-swiper" id="plans-info"
                :breakpoints="JSON.parse(JSON.stringify(swiper_options.breakpoints))"
                :allow-touch-move="swiper_options.allowTouchMove"
                :auto-height="swiper_options.autoHeight"
                :initial-slide="JSON.parse(JSON.stringify(swiper_options.initialSlide))"
                @slideChange="on_slide_change">
            <swiper-slide v-for="slide in swiper_slides" :class="slide === 'main' ? 'plans-main tw-overflow-hidden' : 'plans-detail ' + slide">
                <template v-if="slide === 'main'">
                    <div class="plans-main-inner ctn tw-grid tw-grid-cols-1 md:landscape:tw-grid-cols-2">
                        <div class="plan bg-style" v-for="plan in plans" :key="plan.key" :class="plan.className">
                            <div class="top tw-flex tw-flex-col tw-justify-center tw-items-center">
                                <div class="plan-icon">
                                    <div>
                                        <div class="tw-flex tw-justify-center tw-items-center"><img :src="plan.icon" draggable="false" :alt="plan.title" /></div>
                                    </div>
                                </div>
                                <h3 class="title">{{ t(plan.title) }}</h3>
                                <div class="content tw-w-full">
                                    <ul class="tw-list-none checkmarks">
                                        <li v-for="(_item, index) in plan.main.content.checkmarks" :key="index">
                                            <span class="check fa-solid fa-check"></span>
                                            <span class="text">{{ t('plans.' + plan.className + '.main.content.checkmarks[' + index + ']') }}</span>
                                        </li>
                                    </ul>
                                    <ul class="tw-list-none labels">
                                        <li v-for="(_item, index) in plan.main.content.labels" :key="index">
                                            <span class="label">{{ t('plans.' + plan.className + '.main.content.labels[' + index + '].label') }}</span>
                                            <span class="text">{{ t('plans.' + plan.className + '.main.content.labels[' + index + '].text') }}</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <button type="button" class="btn-view btn-style tw-rounded landscape:tw-gap-x-2.5" :class="plan.main.view_more.className" @click="slide_to(plan.main.view_more.slide_index)">
                                <span class="tw-block">{{ t(plan.main.view_more.text) }}</span>
                                <span class="fa-solid tw-mb-0.5 portrait:tw-hidden" :class="plan.main.view_more.icon"></span>
                            </button>
                        </div>
                    </div>
                    <div class="which-plan tw-flex tw-flex-col tw-items-center tw-gap-y-2">
                        <div class="bubble tw-flex tw-flex-col tw-items-center" @click="scroll_to_comparison">
                            <div class="bubble-inner tw-text-center">{{ t(which_plan.title) }}</div>
                        </div>
                        <div class="pic">
                            <img :src="which_plan.img" draggable="false" :alt="t(which_plan.title)" class="tw-block" />
                        </div>
                    </div>
                </template>
                <template v-else>
                    <div class="plans-detail-inner ctn portrait:tw-flex-col" :class="slide === 'essential' ? 'landscape:tw-flex-row-reverse' : ''">
                        <div class="aside bg-style tw-overflow-hidden">
                            <div class="plan-icon">
                                <div>
                                    <div class="tw-flex tw-justify-center tw-items-center"><img :src="plans[slide].icon" draggable="false" :alt="plans[slide].title" /></div>
                                </div>
                            </div>
                            <h3 class="title">{{ t(plans[slide].title) }}</h3>
                            <div class="buttons">
                                <ul class="tw-list-none tw-flex tw-justify-center tw-gap-x-1">
                                    <li>
                                        <button type="button" class="btn-style tw-rounded" :class="slide === 'essential' ? 'accent-cyan' : 'white'" @click="slide_to(1)">
                                            <span class="tw-block">{{ t('plans.return') }}</span>
                                        </button>
                                    </li>
                                    <li>
                                        <button type="button" class="btn-style tw-rounded" :class="slide === 'essential' ? 'accent-cyan tw-flex-row-reverse' : 'white'" @click="slide_to(slide === 'essential' ? 2 : 0)">
                                            <span class="fa-solid tw-mb-0.5" :class="slide === 'essential' ? 'fa-arrow-right tw-ml-2.5' : 'fa-arrow-left tw-mr-2.5'"></span>
                                            <span class="tw-block" v-text="slide === 'essential' ? t('plans.pro.title_short') : t('plans.essential.title_short')"></span>
                                        </button>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="context tw-overflow-auto">
                            <div class="accordion tw-grid tw-grid-cols-1 tw-gap-y-2 sm:tw-gap-y-4">
                                <div class="accordion-item" v-for="(_item, index) in msg.plans[slide].detail.accordion">
                                    <h4 class="accordion-header">
                                        <button type="button" class="accordion-button collapsed tw-w-full tw-justify-between" data-bs-toggle="collapse" :data-bs-target="'#' + slide + '_accordion_' + index">
                                            <span class="text tw-block">{{ $t(`plans.${slide}.detail.accordion[${index}].title`) }}</span>
                                            <span class="icon tw-flex tw-justify-center tw-items-center fa-solid fa-chevron-down"></span>
                                        </button>
                                    </h4>
                                    <div class="accordion-collapse collapse" :id="slide + '_accordion_' + index">
                                        <div class="accordion-body" v-html="$t(`plans.${slide}.detail.accordion[${index}].content`)"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
            </swiper-slide>
        </swiper>
        <div class="section-plans-block comparison" id="plans-comparison">
            <div class="comparison-inner ctn lg:tw-h-full tw-flex tw-flex-col tw-justify-center tw-items-center">
                <table class="comparison-table" :aria-describedby="t('plans.comparison.table.aria_describedby')">
                    <thead>
                        <tr v-for="(tr, trIndex) in comparison_table.fields" :key="trIndex">
                            <th></th>
                            <th v-for="(_th, thIndex) in tr" :key="thIndex">{{ t('plans.comparison.table.fields[' + trIndex + '][' + thIndex + ']') }}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(tr, trIndex) in comparison_table.data" :key="trIndex">
                            <td v-for="(_td, tdIndex) in tr" :key="tdIndex">{{ t('plans.comparison.table.data[' + trIndex + '][' + tdIndex + ']') }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </section>
</template>
<script>
import { ref, reactive, onMounted } from "vue"
import { useI18n } from "vue-i18n"
import config from "../../config"

import SwiperCore, { Navigation } from "swiper"
import { Swiper, SwiperSlide } from "swiper/vue"
SwiperCore.use([ Navigation ])

export default {
    components: { Swiper, SwiperSlide },
    setup() {
        const { t, locale, messages } = useI18n()
        const msg = JSON.parse(JSON.stringify(messages.value))[locale.value] // i18n message object
        const plans = reactive({
            essential: {
                className: 'essential',
                icon: config.imageUrl('home/plans/icon-essential.svg'),
                title: 'plans.essential.title',
                main: {
                    content: {
                        checkmarks: msg.plans.essential.main.content.checkmarks,
                        labels: msg.plans.essential.main.content.labels
                    },
                    view_more: {
                        className: 'accent-cyan tw-flex-row-reverse',
                        text: 'plans.essential.main.view_more',
                        icon: 'fa-arrow-left',
                        slide_index: 0
                    }
                }
            },
            pro: {
                className: 'pro',
                icon: config.imageUrl('home/plans/icon-pro.svg'),
                title: 'plans.pro.title',
                main: {
                    content: {
                        checkmarks: msg.plans.pro.main.content.checkmarks,
                        labels: msg.plans.pro.main.content.labels
                    },
                    view_more: {
                        className: 'white',
                        text: 'plans.pro.main.view_more',
                        icon: 'fa-arrow-right',
                        slide_index: 2
                    }
                }
            }
        })
        let plans_swiper = ref(null)
        const swiper_slides = reactive(['essential', 'main', 'pro'])
        const swiper_options = reactive({
            allowTouchMove: false,
            initialSlide: 1,
            autoHeight: true,
            breakpoints: {
                '@1.00': {
                    autoHeight: false
                }
            }
        })
        const slide_to = index => {
            plans_swiper.slideTo(index, 500)
        }
        const on_slide_change = () => {
            const is_portrait = window.innerWidth/window.innerHeight < 1
            if ( is_portrait ) {
                const offsetTop = $('#plans-info').offset().top - $('#site-header').height()
                $('html, body').animate({ scrollTop: offsetTop }, 500)
            }
        }
        const which_plan = reactive({
            title: 'plans.which_plan.title',
            img: config.imageUrl('home/plans/which-plan.svg')
        })
        const scroll_to_comparison = () => {
            const is_portrait = window.innerWidth/window.innerHeight < 1
            const offsetTop = !is_portrait ? $('#plans-comparison').offset().top : $('#plans-comparison').offset().top - $('#site-header').height()
            $('html, body').animate({ scrollTop: offsetTop }, 500)
        }
        const comparison_table = reactive({
            fields: msg.plans.comparison.table.fields,
            data: msg.plans.comparison.table.data
        })
        onMounted(() => {
            plans_swiper = document.querySelector('.plans-swiper').swiper
            const accordions = document.querySelectorAll('.accordion')
            accordions.forEach(accordion => {
                accordion.addEventListener('show.bs.collapse', function(e) {
                    e.target.parentNode.classList.add('active')
                })
                accordion.addEventListener('shown.bs.collapse', function() {
                    plans_swiper.updateAutoHeight(200)
                })
                accordion.addEventListener('hide.bs.collapse', function(e) {
                    e.target.parentNode.classList.remove('active')
                })
                accordion.addEventListener('hidden.bs.collapse', function() {
                    plans_swiper.updateAutoHeight(200)
                })
            })
        })
        return {
            t,
            plans, plans_swiper, swiper_slides, swiper_options, slide_to, on_slide_change, msg,
            which_plan, scroll_to_comparison, comparison_table
        }
    }
}
</script>
