import { createI18n } from "vue-i18n"

import zhTW from "./zh-TW/zh-TW.json"
import en from "./en/en.json"

const i18n = createI18n({
    legacy: false,
    locale: 'zh-TW',
    fallbackLocale: 'zh-TW',
    messages: {
        'zh-TW': zhTW,
        'en': en
    }
})

export default i18n
