<template>
    <header id="site-header" class="site-header" :class="{'on-scroll': onscroll}">
        <div class="inner ctn tw-flex tw-justify-between tw-items-center">
            <div class="logo tw-flex tw-items-center">
                <a href="https://www.chtsecurity.com/" class="chts-logo" target="_blank"><img :src="imageUrl('logo.png')" draggable="false" :alt="t('site_name')" /></a>
            </div>
            <scrollactive class="menu tw-flex tw-justify-end tw-items-center" active-class="current" :offset="0" :modify-url="false">
                <ul class="mainlinks tw-list-none">
                    <li v-for="(item, index) in nav" :key="index">
                        <button type="button" class="mainlink-item scrollactive-item" :data-section-selector="'#' + item.id">{{ t(item.name) }}</button>
                    </li>
                </ul>
                <ul class="buttons tw-list-none">
                    <li class="contact">
                        <button type="button" class="btn-style primary" data-bs-toggle="modal" data-bs-target="#contact_modal">
                            <span class="icon fa-solid fa-envelope tw-text-16 xs:tw-text-18"></span>
                            <span class="txt tw-text-14 xs:tw-text-16 tw-ml-2">{{ t('nav.contact') }}</span>
                        </button>
                    </li>
                    <li class="dropdown lang" id="lang">
                        <site-header-button icon="fa-earth-americas" name="lang">
                            <template #menu_inner>
                                <ul class="tw-list-none tw-text-14">
                                    <li v-for="item in lang_menu" :class="item.locale === $route.params.lang ? 'current' : ''" :key="item.name">
                                        <router-link :to="'/' + item.locale">{{ item.name }}</router-link>
                                    </li>
                                </ul>
                            </template>
                        </site-header-button>
                    </li>
                    <li class="dropdown user" id="user" v-if="false">
                        <site-header-button icon="fa-user" name="user">
                            <template #menu_inner>
                                <ul class="tw-list-none tw-text-14">
                                    <li v-for="item in user_menu" :key="item.name">
                                        <a :href="item.url" target="_blank">{{ $t(item.name) }}</a>
                                    </li>
                                </ul>
                            </template>
                        </site-header-button>
                    </li>
                </ul>
            </scrollactive>
        </div>
    </header>
    <contact></contact>
    <site-header-modal name="lang">
        <template #modal_inner>
            <ul class="tw-list-none tw-text-14 tw-flex tw-justify-center tw-items-center tw-gap-x-4">
                <li v-for="item in lang_menu" :class="item.locale === $route.params.lang ? 'current' : ''" :key="item.name">
                    <router-link :to="'/' + item.locale">{{ item.name }}</router-link>
                </li>
            </ul>
        </template>
    </site-header-modal>
    <site-header-modal name="user">
        <template #modal_inner>
            <ul class="tw-list-none tw-text-14 tw-flex tw-justify-center tw-items-center tw-gap-x-4">
                <li v-for="item in user_menu" :key="item.name">
                    <a :href="item.url" target="_blank">{{ $t(item.name) }}</a>
                </li>
            </ul>
        </template>
    </site-header-modal>
</template>
<script>
import { ref, reactive, onMounted } from "vue"
import { useI18n } from "vue-i18n"
import config from "../../config"
import Scrollactive from "vue-scrollactive/src/scrollactive.vue"
import Contact from "../contact/contact"
import SiteHeaderModal from "./site-header-modal.vue"
import SiteHeaderButton from "./site-header-button.vue"

export default {
    components: { Scrollactive, Contact, SiteHeaderModal, SiteHeaderButton },
    setup() {
        const { t } = useI18n()
        const imageUrl = (filename) => {
            return config.imageUrl(filename)
        }
        let onscroll = ref(false)
        let anchor = ref('intro')
        const nav = reactive({
            intro: {
                id: 'intro',
                name: 'nav.intro'
            },
            features: {
                id: 'features',
                name: 'nav.features'
            },
            plans: {
                id: 'plans',
                name: 'nav.plans'
            }
        })
        const lang_menu = reactive([
            {
                locale: 'zh-TW',
                name: '繁體中文'
            },
            {
                locale: 'en',
                name: 'English'
            }
        ])
        const user_menu = reactive([
            {
                name: 'nav.register',
                url: process.env.MIX_SAAS_PLATFORM_URL + '/register'
            },
            {
                name: 'nav.login',
                url: process.env.MIX_SAAS_PLATFORM_URL + '/login'
            }
        ])
        onMounted(() => {
            window.addEventListener('scroll', function () {
                onscroll.value = window.top.scrollY > 0
            })
        })
        return {
            t, imageUrl,
            onscroll, anchor, nav,
            lang_menu, user_menu
        }
    }
}
</script>
