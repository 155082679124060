<template>
    <modal id="contact_modal" dialog-class="contact-modal modal-fullscreen-sm-down" :hide-header="true" :hide-footer="true">
        <form @submit.prevent="submit">
            <div class="tw-grid sm:tw-grid-cols-2 tw-gap-x-4 tw-gap-y-8">
                <input-group
                    :label="t('contact.form.company.label')"
                    :is-required="true"
                    v-model="form.company"
                    :invalid="v$.form.company.$error">
                    <template v-slot:error-messages v-if="v$.form.company.$error">
                        <p v-for="error of v$.form.company.$errors" :key="error.$uid" >{{ error.$message }}</p>
                    </template>
                </input-group>
                <input-group
                    :label="t('contact.form.name.label')"
                    :is-required="true"
                    v-model="form.name"
                    :invalid="v$.form.name.$error">
                    <template v-slot:error-messages v-if="v$.form.name.$errors">
                        <p v-for="error of v$.form.name.$errors" :key="error.$uid" >{{ error.$message }}</p>
                    </template>
                </input-group>
                <input-group
                    :label="t('contact.form.email.label')"
                    :placeholder="t('contact.form.email.placeholder')"
                    :is-required="true"
                    v-model="form.email"
                    input-type="email"
                    :invalid="v$.form.email.$error">
                    <template v-slot:error-messages v-if="v$.form.email.$errors">
                        <p v-for="error of v$.form.email.$errors" :key="error.$uid" >{{ error.$message }}</p>
                    </template>
                </input-group>
                <input-group
                    :label="t('contact.form.phone.label')"
                    :placeholder="t('contact.form.phone.placeholder')"
                    :is-required="true"
                    v-model="form.phone"
                    input-type="tel"
                    :invalid="v$.form.phone.$error">
                    <template v-slot:error-messages v-if="v$.form.phone.$errors">
                        <p v-for="error of v$.form.phone.$errors" :key="error.$uid" >{{ error.$message }}</p>
                    </template>
                </input-group>
                <input-group
                    class="sm:tw-col-span-2"
                    :label="t('contact.form.detect_target.label')"
                    :is-required="true"
                    v-model="form.detect_target"
                    :invalid="v$.form.detect_target.$error">
                    <template v-slot:error-messages v-if="v$.form.detect_target.$errors">
                        <p v-for="error of v$.form.detect_target.$errors" :key="error.$uid" >{{ error.$message }}</p>
                    </template>
                </input-group>
                <input-group
                    class="sm:tw-col-span-2"
                    :label="t('contact.form.case_id.label')"
                    :is-required="true"
                    :invalid="v$.form.case_id.$error"
                    group-class="sm:tw-flex sm:tw-items-center sm:tw-gap-x-4">
                    <template v-slot:custom-content>
                        <div class="tw-flex tw-items-center tw-gap-x-4 tw-mt-4 sm:tw-mt-0">
                            <div class="form-check" v-for="(option, index) in detect_targets" :key="index">
                                <input class="form-check-input" type="radio" name="case_id" :id="option.id" v-model="form.case_id" :value="option.value" />
                                <label class="form-check-label" :for="option.id">{{ t(option.label) }}</label>
                            </div>
                        </div>
                    </template>
                    <template v-slot:error-messages v-if="v$.form.case_id.$errors">
                        <p v-for="error of v$.form.case_id.$errors" :key="error.$uid" >{{ error.$message }}</p>
                    </template>
                </input-group>
                <input-group
                    class="sm:tw-col-span-2"
                    :label="t('contact.form.content.label')"
                    :is-textarea="true"
                    v-model="form.content"
                    :invalid="v$.form.content.$error">
                    <template v-slot:error-messages v-if="v$.form.content.$errors">
                        <p v-for="error of v$.form.content.$errors" :key="error.$uid" >{{ error.$message }}</p>
                    </template>
                </input-group>
                <div class="sm:tw-col-span-2">
                    <vue-recaptcha ref="recaptcha" :sitekey="sitekey" size="invisible" @verify="on_verify" @expired="on_expired" :loadRecaptchaScript="true">
                        <button type="button" class="btn-style accent-cyan-lighter tw-w-full tw-p-4 tw-rounded-md" @click="submit">{{ t("contact.form.submit") }}</button>
                    </vue-recaptcha>
                </div>
            </div>
        </form>
    </modal>
</template>
<script>
import { ref, reactive, onMounted } from "vue"
import { useI18n } from "vue-i18n"
import { required, email, maxLength, helpers } from "@vuelidate/validators"
import { useVuelidate } from "@vuelidate/core"
import { VueRecaptcha } from "vue-recaptcha"
import Swal from "sweetalert2/dist/sweetalert2.min"
import Modal from "../utilities/modal"
import InputGroup from "./input-group"
import config from "../../config"

export default {
    components: { Modal, InputGroup, VueRecaptcha },
    setup() {
        const { t } = useI18n()
        let form = reactive({
            "company": "",
            "name": "",
            "email": "",
            "phone": "",
            "detect_target": "",
            "case_id": "",
            "content": "",
            "g-recaptcha-response": ""
        })
        const is_verified = ref(false)
        const test_regex = (value, regex) => {
            return value.length ? regex.test(value) : true // display error_messages.required if length === 0
        }
        const symbols = value => {
            const regex = /^[{\u4e00-\u9fa5}a-zA-Z\d\s]+$/
            return test_regex(value, regex)
        }
        const phone = value => {
            const regex = /^\d{9,10}(#\d{0,9})?/
            return test_regex(value, regex)
        }
        const fqdn = value => {
            const regex = /^(?!:\/\/)(?=.{1,255}$)((.{1,63}\.){1,127}(?![0-9]*$)[a-z0-9-]+\.?)$/
            return test_regex(value, regex)
        }
        const content_symbols = value => {
            const regex = /^[{\u4e00-\u9fa5}a-zA-Z\d{\uff00-\uff0e}()!@#$%^&*_+＆，！、。—]*$/
            return test_regex(value, regex)
        }
        const detect_targets = reactive([
            {
                label: 'easm-essential',
                value: 'EASM-Essential',
                id: 'case_id_essential'
            },
            {
                label: 'easm-pro',
                value: 'EASM-Pro',
                id: 'case_id_pro'
            }
        ])
        const rules = {
            form: {
                company: {
                    required: helpers.withMessage(t("contact.form.error_messages.required"), required),
                    maxLengthValue: helpers.withMessage(t("contact.form.error_messages.maxLengthValue"), maxLength(255)),
                    symbols: helpers.withMessage(t("contact.form.error_messages.symbols"), symbols)
                },
                name: {
                    required: helpers.withMessage(t("contact.form.error_messages.required"), required),
                    maxLengthValue: helpers.withMessage(t("contact.form.error_messages.maxLengthValue"), maxLength(255)),
                    symbols: helpers.withMessage(t("contact.form.error_messages.symbols"), symbols)
                },
                email: {
                    required: helpers.withMessage(t("contact.form.error_messages.required"), required),
                    email: helpers.withMessage(t("contact.form.error_messages.email"), email),
                    maxLengthValue: helpers.withMessage(t("contact.form.error_messages.maxLengthValue"), maxLength(255))
                },
                phone: {
                    required: helpers.withMessage(t("contact.form.error_messages.required"), required),
                    phone: helpers.withMessage(t("contact.form.error_messages.phone"), phone),
                    maxLengthValue: helpers.withMessage(t("contact.form.error_messages.maxLengthValue"), maxLength(20))
                },
                detect_target: {
                    required: helpers.withMessage(t("contact.form.error_messages.required"), required),
                    maxLengthValue: helpers.withMessage(t("contact.form.error_messages.maxLengthValue"), maxLength(256)),
                    fqdn: helpers.withMessage(t("contact.form.error_messages.fqdn"), fqdn)
                },
                case_id: {
                    required: helpers.withMessage(t("contact.form.error_messages.required"), required)
                },
                content: {
                    content_symbols: helpers.withMessage(t("contact.form.error_messages.symbols"), content_symbols)
                }
            }
        }
        const v$ = useVuelidate(rules, { form })
        const v$f = v$.value.form
        const submit = () => {
            v$f.$touch()
            if ( !v$f.$error && is_verified.value ) {
                Swal.fire({
                    titleText: t('contact.alerts.sending.title'),
                    text: t('contact.alerts.sending.text') + he.encode(form.detect_target),
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: t('contact.alerts.buttons.confirm'),
                    confirmButtonColor: '#DD7E7E',
                    cancelButtonText: t('contact.alerts.buttons.cancel')
                }).then((result) => {
                    if ( result.isConfirmed ) {
                        axios.post('/submit', form).then((response) => {
                            if ( response.data.success ) {
                                form.company = ''
                                form.name = ''
                                form.email = ''
                                form.phone = ''
                                form.detect_target = ''
                                form.case_id = ''
                                form.content = ''
                                v$f.$reset()
                                bootstrap.Modal.getInstance(document.getElementById('contact_modal')).hide()
                                Swal.fire({
                                    titleText: t('contact.alerts.sent.title'),
                                    text: t('contact.alerts.sent.text'),
                                    icon: 'success',
                                    showCancelButton: false,
                                    confirmButtonText: t('contact.alerts.buttons.confirm'),
                                })
                            }
                        }).catch((error) => {
                            Swal.fire({
                                titleText: t('contact.alerts.error.title'),
                                text: error.response.data.message,
                                icon: 'error',
                                showCancelButton: false,
                                confirmButtonText: t('contact.alerts.buttons.confirm'),
                                confirmButtonColor: '#DD7E7E',
                            }).then((resultVal) => {
                                if (resultVal) {
                                    recaptcha.value.reset()
                                }
                            })
                        })

                    }
                })
            }
        }
        const recaptcha = ref(null)
        const sitekey = config.sitekey
        const on_verify = token => {
            if ( token ) {
                is_verified.value = true
                form["g-recaptcha-response"] = token
                submit()
            }
        }
        const on_expired = () => {
            is_verified.value = false
        }
        onMounted(() => {
            document.getElementById('contact_modal').addEventListener('hidden.bs.modal', function () {
                v$f.$reset()
            })
        })
        return {
            t, v$, form, detect_targets, submit, recaptcha, sitekey, on_verify, on_expired, is_verified
        }
    }
}
</script>
